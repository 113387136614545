export default {
  blogerName: 'VEPSE',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@HUVEPRO/videos'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@DanvepseProCasino'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@DanvepseCasinoPro'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@VepseProCasino2'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@vepse1302'
    },
    {
      name: 'telegram',
      url: 'https://t.me/vepse_casino'
    }
  ],
  projects: [
    {
      name: 'gizbo',
      url: 'https://gzb-irrs10.com/c95823005',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225%</strong><div>На первый депозит</div>',
    },
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c96077836',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus ',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/cead1cfed',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c5f52caf5',
      gameTitle: 'Burning Chilli X',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/ce3dcbe9d',
      gameTitle: 'Starda Queen',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs.com/c72afbd90',
      gameTitle: 'Candy Monsta',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>VEPSE</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'VEPSE',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
